body {
  background-color: #ffffff;
  -webkit-transition: width 1s ease-in-out, left 1.5s ease-in-out;
  transition: width 1s ease-in-out, left 1.5s ease-in-out;
  margin: 0;
  font-family: sans-serif;
}

hr {
  border: 0.5px solid rgba(34, 36, 38, 0.15);
}

#app {
  height: 100%;
}

.dayWarn {
  background-color: #ffcbce !important;
}

.wireframe {
  margin-top: 2em;
}

.display-none {
  display: none;
}

.load-course-steps {
  opacity: 0 !important;
  height: 1 !important;
  display: none !important;
}

.load-course-steps.active {
  display: block !important;
  opacity: 1 !important;
  height: auto !important;
  -webkit-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
}

.hide {
  visibility: hidden;
}

.logo {
  width: 60%;
}

.print-only .logo {
  width: 1cm;
}

.nav {
  flex-shrink: 0;
}

.disclaimer {
  font-size: 12px;
}

html,
body {
  height: 100%;
}

@page {
  size: landscape;
}

@media print {
  body,
  .main-container {
    width: auto;
    height: auto;
  }

  #monplan-footer, .hide-on-print {
    display: none !important;
  }
}

@media screen {
  .main-container {
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .print-only {
    display: none !important;
  }

  #main-body .main.container {
    padding-bottom: 5em;
  }
}

.popup ul {
  margin: 0;
  padding: 0;
  padding-left: 1.5em;
}

.sidebar .item > .header {
  text-align: left;
  margin-top: 1em !important;
  margin-left: 0.5em !important;
  font-size: 1.25em !important;
}

.sidebar .message {
  font-size: 1em !important;
}

.sidebar {
  overflow-x: hidden;
}

.sidebar td {
  display: block;
}

.sidebar .active.item {
  background: #e0e0e0 !important;
}

.unit {
  min-height: 90px !important;
}

.unit.draggable:hover {
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.24) !important;
  border-radius: 2px !important;
}

.placeholder.unit,
.free.unit {
  cursor: pointer;
}

/* Adds margin in between a button and another element with class name marginLeft */
.buttons > .marginLeft {
  margin-left: 0.1em !important;
  text-align: center;
}

.filter-margin {
  margin-top: 1em !important;
}

.filter-margin-small {
  margin-top: 0.3em !important;
}

.hide-filter {
  display: none !important;
}

.divider-margin-delete {
  margin-top: 0 !important;
}

.filter-delete {
  display: inline !important;
  position: absolute;
  top: -3px;
  margin-left: 240px !important;
  color: #006cab;
}

.filter-delete:hover {
  cursor: pointer;
}

.filter-button {
  margin-bottom: 10px !important;
}

.rc-slider {
  height: 14px !important;
  padding: 5px 0 !important;
  max-width: 90% !important;
  margin-left: 10px !important;
  margin-bottom: 2em;
}

.rc-slider-track {
  background-color: #006cab !important;
}

.rc-slider-handle {
  border: solid 2px #006cab !important;
}

.rc-slider-dot-active {
  border-color: #006cab !important;
}

* {
  box-sizing: border-box;
}

.study-header {
  font-size: 4rem;
  margin-left: 5px;
  margin-top: 0px;
}

.modalLogo {
  padding-top: 1rem;
}

.viewCourse {
  bottom: 0;
  position: absolute;
}

.viewCareer {
  background: #006cab !important;
}

.recommendedCoursesRow {
  padding: 24px 16px 2px 16px !important;
}

.recommendedCoursesCardRow {
  padding: 0px !important;
}

.courseCard {
  border-radius: 2px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12) !important;
}

.courseCardHeader {
  padding-top: 14px !important;
  padding-bottom: 12px !important;
}

.courseCardHeader > div:nth-child(1) {
  padding-right: 0px !important;
}

.courseCardContent {
  padding: 0px 16px !important;
}

.extra i {
  color: #006cab;
}

button {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.videoBtn {
  width: 100px;
  height: 28px;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.4);
  border: solid 1px #ffffff;
  font-size: 14px;
  color: #fff;
  line-height: 12px;
}

.videoBtn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.videoBtn:focus {
  outline: none;
}

.moreInfoBtn {
  text-transform: uppercase;
  width: 90%;
  height: 30px;
  border-radius: 2px;
  border: none !important;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  margin: 0 auto 16px;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.moreInfoBtn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.moreInfoBtn:focus {
  outline: none;
}

.readMoreBtn {
  text-transform: uppercase;
  width: 90%;
  height: 30px;
  border-radius: 2px;
  border: none !important;
  background-color: #006cab;
  margin: 0 auto 16px;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.readMoreBtn:hover {
  background-color: #005486;
}

.readMoreBtn:focus {
  outline: none;
}

.videoStyle {
  padding: 0 !important;
}

.searchCareer {
  background: transparent !important;
  color: white !important;
  border-radius: 0 !important;
  border: 0 !important;
  border-bottom: rgba(255, 255, 255, 0.6) 1px solid !important;
  font-size: 46px !important;
  padding-bottom: 10px;
}

.searchCareer > div {
  background: transparent !important;
  color: white !important;
  border-radius: 0 !important;
  border: 0 !important;
  padding-left: 10px;
}

.searchCareer > div * {
  background: transparent !important;
  border-radius: 0 !important;
  border: 0 !important;
}

.searchCareer > div > .prompt {
  color: white !important;
}

.searchCareer > .results {
  background: transparent !important;
  color: white !important;
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
  text-align: left;
  width: 100% !important;
  padding-left: 0px;
}

.searchCareer > .input > .prompt::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.searchCareer > .input > .prompt::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.searchCareer > .input > .prompt:focus::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 1) !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.searchCareer > .input > .prompt:focus::-moz-placeholder {
  color: rgba(255, 255, 255, 1) !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.searchCareer > div > .prompt {
  padding: 0 !important;
}

.searchCareer > div > .icon {
  width: 75px !important;
}

@media (max-width: 992px) {
  .searchCareer > .input > .prompt {
    font-size: 0.8em !important;
  }
  .searchCareer > div > .icon {
    font-size: 32px;
  }
  .searchCareer > .results {
    font-size: 0.8em !important;
  }
  .searchCareer > .input {
    padding-left: 5px !important;
  }
  .Aligner-item {
    max-width: 70% !important;
  }

  .careerDetailHeader {
    background-size: 640px;
  }

  #welcome > .button {
    font-size: 1.3rem !important;
  }
  .courseDetailRightColumn p:first-of-type {
    display: none !important;
  }
  .courseDetailRightColumn i {
    font-size: 1.3em !important;
    padding-top: 1px !important;
    margin-right: 0.2em !important;
  }
  .courseDetailRightColumn p {
    font-size: 1em !important;
  }
  .courseDetailRightColumn.big p:first-of-type {
    display: block !important;
  }
  .courseDetailColRightTop div:last-of-type p:last-of-type {
    margin-left: 1.8em;
  }
}
@media (max-width: 840px) {
  .searchCareer > .input > .prompt {
    font-size: 0.6em !important;
  }
  .searchCareer > div > .icon {
    font-size: 28px;
    width: 50px !important;
  }
  .searchCareer > .results {
    font-size: 0.6em !important;
  }
  .searchCareer > .input {
    padding-left: 5px !important;
  }
  .searchCareer {
    padding-bottom: 5px;
  }
  .courseDetailContent p {
    font-size: 0.9em;
  }
}

@media (max-width: 730px) {
  .careerDetailHeader {
    background-size: 550px;
  }
  #welcome > .button {
    font-size: 1.2rem !important;
  }
  .courseCardContent .description {
    font-size: 0.9em;
  }
}
@media (max-width: 666px) {
  .searchCareer > .input > .prompt {
    font-size: 0.5em !important;
  }
  .searchCareer > div > .icon {
    font-size: 22px;
    width: 40px !important;
  }
  .searchCareer > .results {
    font-size: 0.5em !important;
  }
  .searchCareer > .input {
    padding-left: 5px !important;
  }
  .searchCareer {
    padding-bottom: 0px;
  }
  .careerDetailHeader {
    background-size: 520px;
  }
  .courseDetailRightColumn.big p:first-of-type {
    display: none !important;
  }
}
@media (max-width: 600px) {
  .careerDetailHeader {
    background-size: 420px;
  }
}
@media (max-width: 530px) {
  .courseDetailColRightTop {
    display: none !important;
  }
  .careerDetailHeader {
    background-size: 350px;
  }
}
@media (max-width: 450px) {
  .careerDetailHeader {
    background-size: 300px;
  }
}

.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Aligner-item {
  max-width: 50%;
  margin-top: -35%;
}

.Aligner-item--top {
  align-self: flex-start;
}

.Aligner-item--bottom {
  align-self: flex-end;
}

.courseDetailModal > *,
.courseDetailModal .row,
.courseDetailModal .column {
  box-shadow: none !important;
}

.polygonClass h1,
.polygonClass h2 {
  font-weight: 200 !important;
  margin: 0 0 0 10px;
}

.polygonClass h2 {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.courseDetailContent {
  color: rgba(0, 0, 0, 0.68);
}

.courseDetailContent a {
  display: block;
  margin-top: 15px;
}

.courseDetailContent p {
  border-bottom: 1px solid rgba(0, 0, 0, 0.28);
  padding-bottom: 10px;
}

.courseDetailContent ul {
  margin-bottom: 50px;
}

.courseDetailGrid {
  margin: 0 !important;
}

.courseDetailColRightTop {
  margin-top: -3em !important;
  /*background-color: rgba(0,0,0,0.6);
    -webkit-clip-path: polygon(0 2%, 100% 0, 100% 100%, 0 100%);
    -moz-clip-path: polygon(0 2%, 100% 0, 100% 100%, 0 100%);
    -o-clip-path: polygon(0 2%, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 2%, 100% 0, 100% 100%, 0 100%);*/
  padding: 0 !important;
  background: rgba(102, 102, 102, 0);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(49%, rgba(102, 102, 102, 0)),
    color-stop(50%, rgba(102, 102, 102, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(102, 102, 102, 0) 49%,
    rgba(102, 102, 102, 1) 50%
  );
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(49%, rgba(102, 102, 102, 0)),
    color-stop(50%, rgba(102, 102, 102, 1))
  );
  background: linear-gradient(
    to bottom,
    rgba(102, 102, 102, 0) 49%,
    rgba(102, 102, 102, 1) 50%
  );
}

@media (max-width: 992px) {
  .courseDetailColRightTop {
    margin-top: -2em !important;
  }
}
@media (max-width: 700px) {
  .courseDetailColRightTop {
    margin-top: -1.5em !important;
  }
}

.courseModalBtn {
  border-radius: 2px;
  border: none !important;
  background-color: #006cab;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  margin: 0 15px 0px 0;
  color: #f1f1f1;
  padding: 10px 15px;
}

.courseModalBtn.close {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.68);
}

.courseModalBtn:hover {
  background-color: #005486;
}

.courseModalBtn:focus {
  outline: none;
}

.courseModalBtn.close:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.courseDetailRightColumn {
  color: rgba(255, 255, 255, 0.9);
  background-color: #666;
  border-bottom: 1px solid rgba(255, 255, 255, 0.68);
  padding: 20px 10px;
}

.courseDetailRightColumn i {
  font-size: 2em;
  float: left;
  padding-top: 2px;
  margin-right: 10px;
}

.courseDetailRightColumn p {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.small {
  font-size: 0.7em !important;
  padding: 0 !important;
}

.courseDetailRightColumn.big {
  text-align: center;
  padding-top: 1.8em;
  padding-bottom: 1em !important;
  background: url('/img/polygon-2.svg') no-repeat;
}

.courseDetailRightColumn.big i {
  font-size: 2.5em;
  float: none;
  padding-top: 0;
  margin-right: 0;
}

.courseDetailRightColumn.big p {
  font-size: 1em;
  border-bottom: 0;
}

.courseDetailRightColumn.big span {
  font-size: 1.5em;
  margin-left: 0.2em;
}

.btnBack {
  cursor: pointer;
  -vendor-animation-duration: 3s;
}

#WelcomeMessage:focus,
#StartInfo:focus {
  outline: none;
  /*background-color: red;*/
}

.Select-control {
  border-radius: 0 !important;
}

.aboutProjectMenu {
  margin: 0;
  padding: 0;
}

#dropDownBtn {
  color: rgba(0, 0, 0, 0.87);
  height: 100%;
  background: none;
  border: none;
  padding: 0 1.5em;
}

#dropDownBtn:hover {
  outline: none;
  background-color: #eee;
}

.aboutProjectMenu ul,
.aboutProjectMenu li,
.aboutProjectMenu h6 {
  display: none;
}

.aboutProjectMenu.visible {
  padding: 0.4em 0.5em !important;
  position: absolute;
  z-index: 1;
  margin-top: 0.5em;
  background-color: #fff;
  width: 90%;
  box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12),
    0px 2px 10px 0px rgba(34, 36, 38, 0.15) !important;
}

.aboutProjectMenu.visible li {
  display: block;
  font-size: 12pt;
  padding: 0.5em;
  color: rgba(0, 0, 0, 0.87);
}

.aboutProjectMenu.visible li:hover {
  cursor: pointer;
  cursor: hand;
}

.aboutProjectMenu.visible h6 {
  display: block;
  padding: 0.8em 0 0.8em 0.7em;
  margin: 0;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.87);
}

.aboutProjectMenu.visible li a {
  color: rgba(0, 0, 0, 0.87);
}

.aboutProjectMenu.visible li:hover {
  background-color: #ddd;
}

.progressBar {
  width: 100%;
  height: 0.2em;
  background-color: rgba(0, 0, 0, 0.26);
}

.progressBarHighlight {
  height: 0.2em;
  -webkit-transition: width 0.2s; /* Safari */
  transition: width 0.2s;
}

.progressBarHighlightError {
  height: 0.2em;
  background-color: #943827;
}

.link-btn-text {
  /*width: 115.8px;*/
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
  color: #006cab;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
}

.link-btn {
  display: block;
  height: 36px;
  border-radius: 2px;
  background-color: white;
  border: none;
  text-align: center;
  padding: 0 10px;
}

.link-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.heavy-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: rgba(0, 0, 0, 0.87);
}

.light-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: rgba(0, 0, 0, 0.54);
}

.flexrow {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .flexrow {
    display: flex;
    flex-direction: column;
  }

  .light-text {
    text-align: center;
  }

  .heavy-text {
    text-align: center;
  }

  .left-align-text {
    text-align: left;
  }

  .right-align-text {
    text-align: right;
  }

  .courseNameModalCreditPoints {
    display: flex;
  }
}

@media screen and (max-width: 500px) {
  .monashLogoHeader {
    display: none;
  }
}

.flexcol {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.flex-item-sm {
  display: flex;
  flex: 1 1;
  /* min-height: 25px;*/
  /* If you need to look at layout you have created uncomment below... */
}

.flex-item-md {
  display: flex;
  flex: 2 1;
  min-height: 25px;
  /* If you need to look at layout you have created uncomment below... */
}

.flex-item-lg {
  display: flex;
  flex: 3 1;
  min-height: 25px;
  /* If you need to look at layout you have created uncomment below... */
}

/**
author: David
quick fix overflowing scroll-bar for home page on safari browser
 */
.bottomAlignedButton {
  position: relative !important;
  bottom: 6em;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

/**
author: Charlie
styles for filter component transition
 */
/* .showFilters {
  height: 260px;
  opacity: 1;
  -webkit-transition: 0.1s ease-out;
  -moz-transition: 0.1s ease-out;
  -o-transition: 0.1s ease-out;
  transition: 0.1s ease-out;
}

.hideFilters {
  height: 0px;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: 0.1s ease-out;
  -moz-transition: 0.1s ease-out;
  -o-transition: 0.1s ease-out;
  transition: 0.1s ease-out;
} */

a {
  color: #ddd;
}
a:visited {
  color: #cccccc;
}

/*@font-face{
    font-family: "Roboto";
    src: url("./fonts/RobotoCondensed-Regular.ttf")
}

@font-face{
    font-family: "Roboto Light";
    src: url('./fonts/RobotoCondensed-Light.ttf');
}

@font-face {
    font-family: "Roboto Semibold";
    src: url('./fonts/RobotoCondensed-Regular.ttf');
}*/

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  cursor: default;
}
/*
* Monash Getting Started Colours Scheme CSS
*/

/* Header, Footers and Toolbar CSS Property*/
.toolbars {
  width: 100% !important;
  left: 0;
  background-color: #006cab !important;
  color: white !important;
}

.toolbars.dark {
  background-color: #006cab !important;
  color: white !important;
  border: 1px solid #006cab !important;
  box-shadow: -1px 1px #006cab, -2px 2px #006cab, -3px 3px #006cab !important;
  width: 100% !important;
  left: 0;
}

.missingPage {
  min-height: 100%;
  min-width: 100%;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center !important;
  background-color: #ffffff !important;
}

.missingPage > * {
  color: black !important;
}

.feedbackPage {
  min-height: 80%;
  min-width: 100%;
  background-image: -webkit-gradient(
      linear,
      left bottom, left top,
      from(rgba(46, 49, 65, 0.8)),
      to(rgba(46, 49, 65, 0.8))
    ),
    url('https://www.monash.edu/__data/assets/image/0009/976527/law-undergraduate-group-of-students-walking-2016.jpg');
  background-image: -webkit-linear-gradient(
      bottom,
      rgba(46, 49, 65, 0.8),
      rgba(46, 49, 65, 0.8)
    ),
    url('https://www.monash.edu/__data/assets/image/0009/976527/law-undergraduate-group-of-students-walking-2016.jpg');
  background-image: linear-gradient(
      to top,
      rgba(46, 49, 65, 0.8),
      rgba(46, 49, 65, 0.8)
    ),
    url('https://www.monash.edu/__data/assets/image/0009/976527/law-undergraduate-group-of-students-walking-2016.jpg');
  background-size: auto, cover;
  background-attachment: fixed, fixed;
  background-position: center, center;

  /* background-color: #FFFFFF !important; */
}

.feedbackPage > * {
  color: white !important;
}
/* Quick Info Column on Unit Info */
.quickInfo {
  box-shadow: -1px -1px 0px 0px #d4d4d5 !important;
  padding: 5px !important;
  text-align: center !important;
}

/* User Inputs, Search */
.srch:hover {
  border-color: rgba(34, 36, 38, 0.35) !important;
}
.srch.dark * {
  background-color: #003c5b !important;
  color: white !important;
}
.srch.main * {
  background-color: #006cab !important;
  color: white !important;
}
.srch.light * {
  background-color: #009fdb !important;
  color: white !important;
}

/* Placeholder (Input, Search, etc. ) colours */
::-webkit-input-placeholder {
  color: #727272 !important;
  opacity: 0.9 !important;
}
:-moz-placeholder {
  color: #727272 !important;
  opacity: 0.9 !important;
}
::-moz-placeholder {
  color: #727272 !important;
  opacity: 0.9 !important;
}
::-ms-input-placeholder {
  color: #727272 !important;
  opacity: 0.9 !important;
}
/* Animations */
@-webkit-keyframes slide {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slide {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/*
* Course Structure CSS Styling for Table and other Components
*/
.coursetable {
  border-color: transparent !important;
  color: transparent !important;
}

/* Table Header */
@media screen {
  .coursetable > thead > tr > th {
  }
}

@media print {
  .coursetable > thead > tr > th {
    border: none !important;
  }

  .coursetable > thead > tr > th > * {
    border: none !important;
  }
}

.unitControl {
  position: relative !important;
  top: 50% !important;
  -webkit-transform: translateY(50%) !important;
          transform: translateY(50%) !important;
}

.unitControl > * {
  position: relative !important;
  top: 50% !important;
  -webkit-transform: translateY(-25%) !important;
          transform: translateY(-25%) !important;
}

.coursetable > tbody > tr > .unit.error {
  background-color: #d50000 !important;
}

/* No Teaching Periods */
.noteachingperiods > * > h3 > * {
  color: black !important;
}

.noteachingperiods > * > h3 {
  color: black !important;
}

/* User Actions (table) */
.hoverCell {
  background-color: rgba(0, 84, 133, 0.7) !important;
}

.warningicon {
  /*Warning Icon in Units*/
  color: #f2711c !important;
}

/* Course Status */
.status {
  border-bottom: 0.4em solid #47c0bc !important;
  -webkit-transition: all 0.2s ease !important;
  transition: all 0.2s ease !important;
}

.status.error {
  border-bottom: 0.4em solid #ff1923 !important;
  -webkit-transition: all 0.2s ease !important;
  transition: all 0.2s ease !important;
}

.status.error > div > i {
  color: #ff1923 !important;
}

/* Not AX compatible */
/*
:focus {
    outline: none;
}
*/


body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}


.draggableUnit:hover{
    cursor: -webkit-grab !important;
    cursor: grab !important;
}

.unitError:hover{
    cursor: pointer !important;
}
