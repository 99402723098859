/*@font-face{
    font-family: "Roboto";
    src: url("./fonts/RobotoCondensed-Regular.ttf")
}

@font-face{
    font-family: "Roboto Light";
    src: url('./fonts/RobotoCondensed-Light.ttf');
}

@font-face {
    font-family: "Roboto Semibold";
    src: url('./fonts/RobotoCondensed-Regular.ttf');
}*/

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  cursor: default;
}
/*
* Monash Getting Started Colours Scheme CSS
*/

/* Header, Footers and Toolbar CSS Property*/
.toolbars {
  width: 100% !important;
  left: 0;
  background-color: #006cab !important;
  color: white !important;
}

.toolbars.dark {
  background-color: #006cab !important;
  color: white !important;
  border: 1px solid #006cab !important;
  box-shadow: -1px 1px #006cab, -2px 2px #006cab, -3px 3px #006cab !important;
  width: 100% !important;
  left: 0;
}

.missingPage {
  min-height: 100%;
  min-width: 100%;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center !important;
  background-color: #ffffff !important;
}

.missingPage > * {
  color: black !important;
}

.feedbackPage {
  min-height: 80%;
  min-width: 100%;
  background-image: linear-gradient(
      to top,
      rgba(46, 49, 65, 0.8),
      rgba(46, 49, 65, 0.8)
    ),
    url('https://www.monash.edu/__data/assets/image/0009/976527/law-undergraduate-group-of-students-walking-2016.jpg');
  background-size: auto, cover;
  background-attachment: fixed, fixed;
  background-position: center, center;

  /* background-color: #FFFFFF !important; */
}

.feedbackPage > * {
  color: white !important;
}
/* Quick Info Column on Unit Info */
.quickInfo {
  box-shadow: -1px -1px 0px 0px #d4d4d5 !important;
  padding: 5px !important;
  text-align: center !important;
}

/* User Inputs, Search */
.srch:hover {
  border-color: rgba(34, 36, 38, 0.35) !important;
}
.srch.dark * {
  background-color: #003c5b !important;
  color: white !important;
}
.srch.main * {
  background-color: #006cab !important;
  color: white !important;
}
.srch.light * {
  background-color: #009fdb !important;
  color: white !important;
}

/* Placeholder (Input, Search, etc. ) colours */
::-webkit-input-placeholder {
  color: #727272 !important;
  opacity: 0.9 !important;
}
:-moz-placeholder {
  color: #727272 !important;
  opacity: 0.9 !important;
}
::-moz-placeholder {
  color: #727272 !important;
  opacity: 0.9 !important;
}
::-ms-input-placeholder {
  color: #727272 !important;
  opacity: 0.9 !important;
}
/* Animations */
@-webkit-keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

/*
* Course Structure CSS Styling for Table and other Components
*/
.coursetable {
  border-color: transparent !important;
  color: transparent !important;
}

/* Table Header */
@media screen {
  .coursetable > thead > tr > th {
  }
}

@media print {
  .coursetable > thead > tr > th {
    border: none !important;
  }

  .coursetable > thead > tr > th > * {
    border: none !important;
  }
}

.unitControl {
  position: relative !important;
  top: 50% !important;
  transform: translateY(50%) !important;
}

.unitControl > * {
  position: relative !important;
  top: 50% !important;
  transform: translateY(-25%) !important;
}

.coursetable > tbody > tr > .unit.error {
  background-color: #d50000 !important;
}

/* No Teaching Periods */
.noteachingperiods > * > h3 > * {
  color: black !important;
}

.noteachingperiods > * > h3 {
  color: black !important;
}

/* User Actions (table) */
.hoverCell {
  background-color: rgba(0, 84, 133, 0.7) !important;
}

.warningicon {
  /*Warning Icon in Units*/
  color: #f2711c !important;
}

/* Course Status */
.status {
  border-bottom: 0.4em solid #47c0bc !important;
  transition: all 0.2s ease !important;
}

.status.error {
  border-bottom: 0.4em solid #ff1923 !important;
  transition: all 0.2s ease !important;
}

.status.error > div > i {
  color: #ff1923 !important;
}

/* Not AX compatible */
/*
:focus {
    outline: none;
}
*/
